import { create } from "zustand";
import {
    Connection,
    Edge,
    EdgeChange,
    Node,
    NodeChange,
    addEdge,
    OnNodesChange,
    OnEdgesChange,
    OnConnect,
    applyNodeChanges,
    applyEdgeChanges,
    ConnectionLineType,
} from "reactflow";
import { getRemainingNodes, getNodesWithRemovedBranches } from "utils/graphs";
import { IBlock, TConditionFields } from "types";

export type RFState = {
    initialNodes: Node[];
    nodes: Node[];
    edges: Edge[];
    blocks: IBlock[];
    conditionFields: TConditionFields;
    setInitialNodes: (nodes: Node[]) => void;
    setNodes: (nodes: Node[]) => void;
    setEdges: (edges: Edge[]) => void;
    onNodesChange: OnNodesChange;
    onEdgesChange: OnEdgesChange;
    onConnect: OnConnect;
    deleteNode: (id: string) => void;
    deleteBranchNode: (id: string, branches?: Node[]) => void;
    setBlocks: (blocks: IBlock[]) => void;
    setConditionFields: (conditionFields: TConditionFields) => void;
    toggleNodeActionDropdown: (id?: string, isVisible?: boolean) => void;
    onChangeNodeLabel: (id: string, label: string) => void;
};

const useStore = create<RFState>((set, get) => ({
    initialNodes: [],
    nodes: [],
    edges: [],
    blocks: [],
    conditionFields: [],
    setInitialNodes: (nodes: Node[]) => {
        set({ initialNodes: nodes });
    },
    setNodes: (nodes: Node[]) => {
        set({ nodes });
    },
    setEdges: (edges: Edge[]) => {
        set({ edges });
    },
    onNodesChange: (changes: NodeChange[]) => {
        set({
            nodes: applyNodeChanges(changes, get().nodes),
        });
    },
    onEdgesChange: (changes: EdgeChange[]) => {
        set({
            edges: applyEdgeChanges(changes, get().edges),
        });
    },
    onConnect: (connection: Connection) => {
        set({
            edges: addEdge({ ...connection, type: ConnectionLineType.SmoothStep }, get().edges),
        });
    },
    deleteNode: (id: string) => {
        const node = get().nodes.find((nd) => nd.id === id);
        if (node) {
            const nodes = getRemainingNodes(node, get().nodes, get().edges);
            set({ nodes });
        }
    },
    deleteBranchNode: (id: string, branches?: Node[]) => {
        const currentNode = get().nodes.find((node) => node.id === id);
        if (currentNode) {
            const { nodes, edges } = getNodesWithRemovedBranches(currentNode, get().nodes, get().edges, branches);
            set({ nodes, edges });
        }
    },
    setBlocks: (blocks: IBlock[]) => {
        set({ blocks });
    },
    setConditionFields: (conditionFields: TConditionFields) => {
        set({ conditionFields });
    },
    toggleNodeActionDropdown: (id?: string, isVisible = false) => {
        // If id is null then close all dropdowns
        const newNodes = get().nodes.map((node) => {
            if (node.id === id) {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        visibleActions: isVisible,
                    },
                };
            }

            return {
                ...node,
                data: {
                    ...node.data,
                    visibleActions: false,
                },
            };
        });

        set({ nodes: newNodes });
    },
    onChangeNodeLabel: (id: string, label: string) => {
        const newNodes = get().nodes.map((node) => {
            if (node.id === id) {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        label,
                    },
                };
            }

            return node;
        });

        set({ nodes: newNodes });
    },
}));

export default useStore;
