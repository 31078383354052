/* eslint-disable @typescript-eslint/no-misused-promises */
import React from "react";

import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import type { UploadProps, UploadFile } from "antd";
import { Button, Form, Input, Upload, Row, Col, message } from "antd";
import { isEmpty } from "lodash-es";
import { shallow } from "zustand/shallow";
import { RcFile, UploadChangeParam } from "antd/es/upload";

import useStore, { RFState } from "stores";
import { IFormsProps } from "../../../types";
import TinyMCEEditor from "../../TinyMCEEditor/TinyMCEEditor";
import FormFooter from "../FormFooter";
import TemplateTagDropdown from "components/TemplateTagDropdown";
import { useGetTemplate } from "hooks/features/templates/queries";
import {
    useSendTestSMSMutation,
    useCreateTemplateMutation,
    useUpdateTemplateMutation,
    useUploadAttachmentFile,
} from "hooks/features/templates/mutations";
import { parseDoubleBraces } from "utils/parseDoubleBraces";
import { UploadItemList } from "./SendSMSForm.styled";
import { useRemoveFile } from "hooks/features/files/mutations";
import Spinner from "components/Spinner";

interface IFormInputs {
    template: string;
    to?: string;
    message: string;
    messageText?: string;
    testPhoneNumber: string;
    attachmentFiles: UploadChangeParam;
}

const selector = (state: RFState) => ({
    conditionFields: state.conditionFields,
});

export default function SendSMSForm({ initialData, canDelete = true, onCancel, onSubmit, onDelete }: IFormsProps) {
    const [fileList, setFileList] = React.useState<UploadFile[]>([]);
    const [text, setText] = React.useState<string>("");
    const [form] = Form.useForm();
    const { conditionFields } = useStore(selector, shallow);
    const sendTestSMSMutation = useSendTestSMSMutation();
    const [messageApi, contextHolder] = message.useMessage();
    const createTemplateMutation = useCreateTemplateMutation();
    const updateTemplateMutation = useUpdateTemplateMutation(Number(initialData?.templateId));
    const uploadAttachmentFile = useUploadAttachmentFile();
    const removeFile = useRemoveFile();
    const { data: templateDetailData, isLoading: isTemplateDetailDataLoading } = useGetTemplate(
        Number(initialData?.templateId),
        !!initialData?.templateId
    );
    const initialValues = {
        templateId: String(initialData?.templateId ?? ""),
        to: String(initialData?.to ?? ""),
        message: String(initialData?.message ?? ""),
        testPhoneNumber: String(initialData?.testPhoneNumber ?? ""),
    };

    const getAttachmentFileUrl = (url: string) => {
        return `${process.env.REACT_APP_BASE_URL || "https://app.pilotpractice.com"}/${url}`;
    };

    const uploadProps: UploadProps = {
        ...(!!initialData?.templateId && {
            customRequest: async (options: any) => {
                const { onSuccess, onError } = options;
                try {
                    const data = new FormData();
                    data.append("attachmentFile", options.file as RcFile);

                    const attachmentFile = await uploadAttachmentFile.mutateAsync({
                        templateId: Number(initialData?.templateId),
                        formData: data,
                    });
                    setFileList([
                        ...fileList,
                        {
                            uid: String(attachmentFile.id),
                            name: attachmentFile.originalName,
                            status: "done",
                            url: getAttachmentFileUrl(attachmentFile.url),
                        },
                    ]);
                    onSuccess("Ok");
                } catch (e) {
                    onError("Error");
                }
            },
        }),
        beforeUpload(file) {
            if (!initialData?.templateId) {
                setFileList([...fileList, file]);

                return false;
            }
        },
        onRemove: async (file) => {
            try {
                if (initialData?.templateId) {
                    await removeFile.mutateAsync(Number(file.uid));
                }
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);
                setFileList(newFileList);
            } catch (e) {
                console.log(e);
            }
        },
        fileList,
        itemRender: (originNode) => {
            return <UploadItemList>{originNode}</UploadItemList>;
        },
        showUploadList: {
            removeIcon: <DeleteOutlined />,
        },
    };

    const getAttachmentFiles = React.useCallback(() => {
        return (
            templateDetailData?.attachmentFiles?.map((attachmentFile) => {
                return {
                    uid: String(attachmentFile.id),
                    name: attachmentFile.originalName,
                    status: "done",
                    url: getAttachmentFileUrl(attachmentFile.url),
                } as UploadFile;
            }) ?? []
        );
    }, [templateDetailData]);

    const sumbit = async (data: IFormInputs) => {
        let newTemplateId = initialData?.templateId ?? null;

        // Create SMS template
        if (!initialData?.templateId) {
            const formData = new FormData();
            formData.append("name", initialData.label as string);
            formData.append("type", "sms");
            formData.append("to", String(data.to));
            formData.append("content", data.message);
            fileList?.forEach((file: UploadFile) => {
                formData.append("attachment_files[]", file as RcFile);
            });
            const response = await createTemplateMutation.mutateAsync(formData);

            newTemplateId = response.id;
        } else {
            await updateTemplateMutation.mutateAsync({
                name: initialData.label as string,
                to: data.to,
                content: data.message,
            });
        }

        onSubmit({
            ...data,
            messageText: text,
            ...(newTemplateId ? { templateId: newTemplateId } : { templateId: Number(initialData.templateId) }),
        });
    };

    const onSendTestSMS = async () => {
        try {
            const testPhoneNumber = form.getFieldValue("testPhoneNumber");

            if (!testPhoneNumber) {
                messageApi.open({
                    type: "error",
                    content: "Test Phone Number is missing.",
                });

                return;
            }

            const formData = new FormData();
            formData.append("message", parseDoubleBraces(text));
            formData.append("test_phone_number", String(testPhoneNumber));
            if (!initialData.templateId) {
                fileList?.forEach((file: UploadFile) => {
                    formData.append("attachment_files[]", file as RcFile);
                });
            } else {
                formData.append("template_id", String(initialData?.templateId));
            }

            await sendTestSMSMutation.mutateAsync({ formData });
            messageApi.open({
                type: "success",
                content: "The test SMS is sent successfully.",
            });
        } catch (e) {
            console.log(e);
            messageApi.open({
                type: "error",
                content: "Cannot send test SMS.",
            });
        }
    };

    const updateFieldValueWithTag = (fieldName: string, value: string) => {
        const currentValue = form.getFieldValue(fieldName);

        form.setFieldValue(fieldName, `${currentValue}{{${value}}}`);
    };

    React.useEffect(() => {
        if (!!initialData?.templateId && !!templateDetailData) {
            setFileList(() => [...getAttachmentFiles()]);
        }
    }, [initialData, templateDetailData]);

    React.useEffect(() => {
        if (initialData && !isEmpty(initialData)) {
            form.setFieldValue("templateId", String(initialData?.templateId ?? ""));
            form.setFieldValue("to", String(initialData?.to ?? ""));
            form.setFieldValue("message", String(initialData?.message ?? ""));

            if (initialData?.templateId) {
                const messageText = String(initialData?.messageText ?? "");
                setText(messageText);
            }
        }
    }, [initialData, form]);

    if (!!initialData?.tempate && isTemplateDetailDataLoading) return <Spinner />;

    return (
        <div className="form-layout">
            {contextHolder}
            <Form layout="vertical" form={form} initialValues={initialValues} onFinish={sumbit}>
                <Form.Item
                    label="To"
                    name="to"
                    rules={[
                        {
                            required: true,
                            message: "To is required field",
                        },
                        {
                            message: "To can only be an phone number or {{phone}}",
                            validator: (_, value: string) => {
                                const phonePattern = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/g;
                                const phoneTagPattern = /^{{phone}}$/g;
                                if (isEmpty(value) || phonePattern.test(value) || phoneTagPattern.test(value)) {
                                    return Promise.resolve();
                                }

                                return Promise.reject("To can only be an phone number or {{phone}}");
                            },
                        },
                    ]}
                >
                    <Input
                        placeholder="To"
                        suffix={
                            <TemplateTagDropdown fieldName="to" only={["phone"]} onSelect={updateFieldValueWithTag} />
                        }
                        style={{ width: "50%" }}
                    />
                </Form.Item>
                <Form.Item
                    name="message"
                    label="Message"
                    rules={[{ required: true, message: "Message is required field" }]}
                >
                    <TinyMCEEditor
                        menubar=""
                        toolbar="undo redo | templatetags"
                        conditionFields={conditionFields}
                        onChangeText={(v) => setText(v)}
                    />
                </Form.Item>
                <Upload {...uploadProps}>
                    <Button icon={<UploadOutlined />}>Add attachment</Button>
                </Upload>
                <Form.Item label="Test Phone Number" style={{ marginTop: "24px" }}>
                    <Row gutter={8}>
                        <Col span={14}>
                            <Form.Item
                                name="testPhoneNumber"
                                rules={[
                                    {
                                        pattern: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
                                        message: "Test Phone Number is not a valid phone number.",
                                    },
                                ]}
                            >
                                <Input placeholder="Test Phone Number" />
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Button type="default" onClick={onSendTestSMS} block>
                                Send Test SMS
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
                <FormFooter onCancel={onCancel} canDelete={canDelete} onDelete={onDelete} />
            </Form>
        </div>
    );
}
