import React, { useEffect } from "react";

import { v4 as uuidv4 } from "uuid";
import { Card, Button, Input } from "antd";
import { PlusCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

import useConditionEditorStore from "stores/useConditionEditorStore";
import ConditionSets from "../ConditionSets";
import { IBranchProps } from "types";
import { BranchWrapperStyled } from "./Branch.styled";

export default function Branch({ branch, canDelete = false, isSelected = false, isDefault = false }: IBranchProps) {
    const ref = React.useRef<HTMLDivElement>(null);
    const { onBranchChange, deleteBranch, addConditionSet } = useConditionEditorStore();

    const handledeleteBranch = (id: string) => {
        if (id) {
            deleteBranch(String(id));
        }
    };

    const handleAddConditionSet = (branchId: string) => {
        const conditionSet = [
            {
                id: uuidv4(),
            },
        ];
        addConditionSet(branchId, conditionSet);
    };

    useEffect(() => {
        if (ref && isSelected) {
            setTimeout(() => {
                if (ref.current) {
                    ref.current.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                    });
                }
            }, 500);
        }
    }, [ref, isSelected]);

    return (
        <div ref={ref}>
            <BranchWrapperStyled isSelected={isSelected}>
                <Card className="branch" key={branch.id}>
                    {canDelete && (
                        <div className="btn-actions">
                            <Button
                                className="btn-delete"
                                icon={<CloseCircleOutlined />}
                                onClick={() => handledeleteBranch(String(branch.id))}
                                danger
                                type="link"
                            ></Button>
                        </div>
                    )}
                    <div className="branch-name-input">
                        <Input
                            placeholder="Branch Name"
                            value={branch.name || ""}
                            onChange={(e) => onBranchChange(String(branch.id), e.target.value)}
                            disabled={isDefault}
                        />
                    </div>
                    <div className="condition-sets">
                        {branch?.conditionSets && (
                            <ConditionSets
                                branchId={String(branch.id)}
                                conditionSets={branch?.conditionSets ?? []}
                                isDefault={isDefault}
                            />
                        )}
                        {!isDefault && (
                            <div className="bottom-btn-actions">
                                <Button
                                    className="btn-add"
                                    icon={<PlusCircleOutlined />}
                                    type="link"
                                    onClick={() => handleAddConditionSet(String(branch.id))}
                                >
                                    Add condition set
                                </Button>
                            </div>
                        )}
                    </div>
                </Card>
            </BranchWrapperStyled>
        </div>
    );
}
