import React from "react";

import { startCase, camelCase } from "lodash-es";
import { shallow } from "zustand/shallow";

import Action from "../../assets/action.svg";
import GrabMe from "../../assets/grabme.svg";
import { IBlock, IBlocksProps } from "../../types";
import { BlockListStyled, Nav, Subnav } from "./Blocks.styled";
import useStore, { RFState } from "../../stores";
import { BLOCKS } from "contestants/blockIcons";

const tabs = ["Triggers", "Actions", "Workflows"];

const selector = (state: RFState) => ({
    blocks: state.blocks,
});

export default function Blocks({ onDrag, onDragStart, onDragEnd }: IBlocksProps) {
    const { blocks } = useStore(selector, shallow);
    const [activeTab, setActiveTab] = React.useState<string>("Triggers");

    const renderTriggers = () => {
        return (
            <>
                {blocks
                    ?.filter((block) => block.workflowType === "trigger")
                    ?.map((trigger: IBlock, index: number) => {
                        const icon = BLOCKS.find((block) => block.type === trigger.type)?.icon;

                        return (
                            <div
                                key={`${trigger.id}_${index}`}
                                className="blockelem create-flowy noselect"
                                draggable
                                onDragStart={(event) => onDragStart(event, trigger.type)} // TODO: Change to use block id instead.
                                onDrag={(event) => onDrag(event)}
                                onDragEnd={(event) => onDragEnd(event)}
                            >
                                <div className="grabme">
                                    <img src={GrabMe} alt="grabme" />
                                </div>
                                <div className="blockin">
                                    <div className="blockico">
                                        {/* <span></span> */}
                                        {icon ? <i className="icon">{icon}</i> : <img src={Action} alt="action" />}
                                    </div>
                                    <div className="blocktext">
                                        <p className="blocktitle">{trigger.name}</p>
                                        <p className="blockdesc">{trigger.description}</p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </>
        );
    };

    const renderActions = () => {
        return (
            <>
                {blocks
                    .filter((block) => block.workflowType === "action")
                    .map((action, index) => (
                        <div
                            key={`${action.type}_${index}`}
                            className="blockelem create-flowy noselect"
                            draggable
                            onDragStart={(event) => onDragStart(event, action.type)} // TODO: Change to use block id instead.
                            onDrag={(event) => onDrag(event)}
                        >
                            <div className="grabme">
                                <img src={GrabMe} alt="grabme" />
                            </div>
                            <div className="blockin">
                                <div className="blockico">
                                    {/* <span></span> */}
                                    {action?.icon ? (
                                        <i className="icon">{action.icon}</i>
                                    ) : (
                                        <img src={Action} alt="action" />
                                    )}
                                </div>
                                <div className="blocktext">
                                    <p className="blocktitle">
                                        {action?.name ?? startCase(camelCase(action.type.replace("_", " ")))}
                                    </p>
                                    <p className="blockdesc">Performs or edits a specified action</p>
                                </div>
                            </div>
                        </div>
                    ))}
            </>
        );
    };

    const renderWorkflows = () => {
        return blocks
            .filter((block) => block.workflowType === "workflow")
            .map((action, index) => (
                <div
                    key={`${action.type}_${index}`}
                    className="blockelem create-flowy noselect"
                    draggable
                    onDragStart={(event) => onDragStart(event, action.type)} // TODO: Change to use block id instead.
                    onDrag={(event) => onDrag(event)}
                >
                    <div className="grabme">
                        <img src={GrabMe} alt="grabme" />
                    </div>
                    <div className="blockin">
                        <div className="blockico">
                            {/* <span></span> */}
                            {action?.icon ? <i className="icon">{action.icon}</i> : <img src={Action} alt="action" />}
                        </div>
                        <div className="blocktext">
                            <p className="blocktitle">{startCase(camelCase(action.type.replace("_", " ")))}</p>
                            <p className="blockdesc">Performs or edits a specified action</p>
                        </div>
                    </div>
                </div>
            ));
    };

    return (
        <>
            <Subnav>
                {tabs.map((tab, index) => (
                    <Nav
                        key={`${tab}_${index}`}
                        className={`${activeTab === tab ? "navactive" : "navdisabled"} side`}
                        onClick={() => setActiveTab(tab)}
                        aria-hidden
                        data-testid={`${tab}`}
                    >
                        {tab}
                    </Nav>
                ))}
            </Subnav>
            <BlockListStyled id="blocklist">
                <>
                    {activeTab === "Triggers" && <>{renderTriggers()}</>}
                    {activeTab === "Actions" && <>{renderActions()}</>}
                    {activeTab === "Workflows" && <>{renderWorkflows()}</>}
                </>
            </BlockListStyled>
        </>
    );
}
