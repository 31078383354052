import React from "react";

import { v4 as uuidv4 } from "uuid";
import { Button, Space } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash-es";

import useConditionEditorStore from "stores/useConditionEditorStore";
import { ICondition, IConditionEditorProps } from "types";
import Branch from "./Branch/Branch";
import { ConditionEditorWrapperStyled } from "./ConditionEditor.styled";

export default function ConditionEditor({ initialValues = [], onChange, selectedBranch }: IConditionEditorProps) {
    const { branches, setBranches, addBranch } = useConditionEditorStore();

    const handleAddBranch = () => {
        const newCondition: ICondition = {
            id: uuidv4(),
        };

        addBranch({
            id: uuidv4(),
            name: "",
            isDefault: false,
            conditionSets: [[newCondition]],
        });
    };

    const renderBranches = () => {
        return branches.map((branch) => {
            if (branch.isDefault) return null;

            const canDelete =
                branches?.filter((branchItem) => !branchItem?.isDefault).length > 1 && !(branch?.isDefault ?? false);

            return (
                <Branch
                    key={branch.id}
                    branch={branch}
                    canDelete={canDelete}
                    isSelected={branch.id === selectedBranch}
                    isDefault={branch?.isDefault}
                />
            );
        });
    };

    React.useEffect(() => {
        if (initialValues && !isEmpty(initialValues)) {
            setBranches(initialValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValues]);

    React.useEffect(() => {
        if (onChange) {
            onChange(branches);
        }
    }, [branches, onChange]);

    return (
        <ConditionEditorWrapperStyled>
            <div className="branches" style={{ marginBottom: "20px" }}>
                <Space direction="vertical" size={16} style={{ width: "100%" }}>
                    {renderBranches()}
                </Space>
                <div className="bottom-btn-actions">
                    <div className="btn-add">
                        <Button icon={<PlusCircleOutlined />} type="link" onClick={() => handleAddBranch()}>
                            Add branch
                        </Button>
                    </div>
                </div>
            </div>
        </ConditionEditorWrapperStyled>
    );
}
