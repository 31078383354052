import { IWorkflowResponse } from "types";
import api from "./axios";

export interface Trigger {
    id: number;
    name: string;
    type: string;
    conditionFields: any;
    description: string;
    triggerable: string;
    createdAt: Date;
    updatedAt: Date;
}

export type GetTriggerResponse = Trigger[];

const getTriggers = async () => {
    const response = await api.get<GetTriggerResponse, GetTriggerResponse>("/api/workflow/triggers");

    return response;
};

const createWorkflow = async (params: IWorkflowResponse) => {
    const response = await api.post<IWorkflowResponse, IWorkflowResponse>("/api/workflows", params);

    return response;
};

const getWorkflowById = async (id: number) => {
    const response = await api.get<IWorkflowResponse, IWorkflowResponse>(`/api/workflows/${id}`);

    return response;
};

const updateWorkflow = async (id: number, params: IWorkflowResponse) => {
    const response = await api.put<IWorkflowResponse, IWorkflowResponse>(`/api/workflows/${id}`, params);

    return response;
};

export const WorkflowAPI = {
    getTriggers,
    createWorkflow,
    getWorkflowById,
    updateWorkflow,
};
