/* eslint-disable @typescript-eslint/no-misused-promises */
import React from "react";

import { Form, Input, InputNumber, Select } from "antd";
import { omit } from "lodash-es";

import { IFormsProps } from "../../../types";
import FormFooter from "../FormFooter";

interface IFormInputs {
    title: string;
    duration: string;
    durationUnit: string;
}

const DURATION_UNITS = ["minute", "hour", "day"];

export default function WaitForm({ initialData, canDelete = true, onCancel, onSubmit, onDelete }: IFormsProps) {
    const [form] = Form.useForm();
    const initialValues = {
        title: initialData.label,
        duration: String(initialData?.duration ?? 0),
        durationUnit: String(initialData?.durationUnit ?? ""),
    };

    const sumbit = (data: IFormInputs) => {
        onSubmit({
            ...omit(data, ["title"]),
            label: data.title,
        });
    };

    return (
        <div className="form-layout">
            <Form layout="vertical" form={form} initialValues={initialValues} onFinish={sumbit}>
                <Form.Item
                    label="Action Name"
                    name="title"
                    rules={[{ required: true, message: "Action Name is required field" }]}
                >
                    <Input placeholder="Action Name" />
                </Form.Item>
                <Form.Item
                    label="Duration"
                    name="duration"
                    rules={[{ required: true, message: "Duration is required field" }]}
                >
                    <InputNumber placeholder="Duration" min="0" style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                    label="Duration Unit"
                    name="durationUnit"
                    rules={[{ required: true, message: "Duration Unit is required field" }]}
                >
                    <Select>
                        {DURATION_UNITS.map((durationUnit) => (
                            <Select.Option key={durationUnit} value={durationUnit}>
                                {durationUnit}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <FormFooter onCancel={onCancel} canDelete={canDelete} onDelete={onDelete} />
            </Form>
        </div>
    );
}
