import styled from "styled-components";

interface Props {
    isDefault: boolean;
}

export const ConditionWrapperStyled = styled.div<Props>`
    .ant-card-body {
        padding-top: 0;
        padding-bottom: ${({ isDefault }) => (isDefault ? "24px" : "0")};
    }
`;

export const ConditionWrapperFormStyled = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .conditon-form {
        width: 80%;
        display: flex;
        justify-content: start;
        gap: 8px;
    }
`;
