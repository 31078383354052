import React from "react";

import { v4 as uuidv4 } from "uuid";
import { Button, Card, Space } from "antd";
import { PlusCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

import { ICondition, IConditionSetsProps } from "types";
import useConditionEditorStore from "stores/useConditionEditorStore";
import Condition from "../Condition";

export default function ConditionSets({ branchId, conditionSets, isDefault = false }: IConditionSetsProps) {
    const { deleteConditionSet, addCondition } = useConditionEditorStore();

    const handleAddCondition = (conditionSetIndex: number) => {
        const newCondition = {
            id: uuidv4(),
        };
        addCondition(branchId, conditionSetIndex, newCondition);
    };

    const handleDeleteConditionSet = (conditionSetIndex: number) => {
        deleteConditionSet(branchId, conditionSetIndex);
    };

    const renderCondtions = (conditions: ICondition[], conditionSetIndex: number) => {
        return (
            <div className="conditions">
                <Space direction="vertical" size={16} style={{ width: "100%" }}>
                    {conditions.map((condition, conditionIndex) => (
                        <Condition
                            key={`condition_${conditionIndex}`}
                            branchId={branchId}
                            conditionSetIndex={conditionSetIndex}
                            conditionIndex={conditionIndex}
                            condition={condition}
                            canDelete={conditions.length > 1}
                            isDefault={isDefault}
                        />
                    ))}
                </Space>
                {!isDefault && (
                    <div className="bottom-btn-actions">
                        <Button
                            className="btn-add"
                            icon={<PlusCircleOutlined />}
                            type="link"
                            onClick={() => handleAddCondition(conditionSetIndex)}
                        >
                            Add condition
                        </Button>
                    </div>
                )}
            </div>
        );
    };

    const renderConditionSets = () => {
        return conditionSets.map((conditionSet, conditionSetIndex) => (
            <Card className={`condition-set ${conditionSetIndex}`} key={`${branchId}_${conditionSetIndex}`}>
                {conditionSets.length > 1 && (
                    <div className="btn-actions">
                        <div>{conditionSetIndex >= 1 && <span>OR</span>}</div>
                        <Button
                            className="btn-delete"
                            icon={<CloseCircleOutlined />}
                            onClick={() => handleDeleteConditionSet(conditionSetIndex)}
                            danger
                            type="link"
                        ></Button>
                    </div>
                )}
                {renderCondtions(conditionSet, conditionSetIndex)}
            </Card>
        ));
    };

    return (
        <Space direction="vertical" size={16} style={{ width: "100%" }}>
            {renderConditionSets()}
        </Space>
    );
}
