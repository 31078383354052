/* eslint-disable @typescript-eslint/no-misused-promises */
import React from "react";

import { Form, Input, Select } from "antd";
import { omit } from "lodash-es";

import { IFormsProps } from "../../../types";
import TinyMCEEditor from "../../TinyMCEEditor/TinyMCEEditor";
import FormFooter from "../FormFooter";

interface IFormInputs {
    title: string;
    taskName: string;
    description: string;
    assignTo: string;
    dueIn: string;
}

const Option = Select.Option;

export default function CreateTaskForm({ initialData, canDelete = true, onCancel, onSubmit, onDelete }: IFormsProps) {
    const [form] = Form.useForm();
    const initialValues = {
        title: initialData.label,
        taskName: String(initialData?.taskName ?? ""),
        description: String(initialData?.description ?? ""),
        assignTo: String(initialData?.assignTo ?? ""),
        dueIn: String(initialData?.dueIn ?? ""),
    };

    const sumbit = (data: IFormInputs) => {
        console.log(data);
        onSubmit({
            ...omit(data, ["title"]),
            label: data.title,
        });
    };

    return (
        <div className="form-layout">
            <Form layout="vertical" form={form} initialValues={initialValues} onFinish={sumbit}>
                <Form.Item
                    label="Action Name"
                    name="title"
                    rules={[{ required: true, message: "Action Name is required field" }]}
                >
                    <Input placeholder="Action Name" />
                </Form.Item>
                <Form.Item
                    label="Task Name"
                    name="taskName"
                    rules={[{ required: true, message: "Task Name is required field" }]}
                >
                    <Input placeholder="Task Name" />
                </Form.Item>
                <Form.Item name="description" label="Description">
                    <TinyMCEEditor />
                </Form.Item>
                <Form.Item
                    name="assignTo"
                    label="Assign To"
                    rules={[{ required: true, message: "Assign To is required field" }]}
                >
                    <Select placeholder="Select" allowClear showSearch optionFilterProp="children">
                        <Option value="1">User 1</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="dueIn"
                    label="Due In"
                    rules={[{ required: true, message: "Due In is required field" }]}
                >
                    <Select placeholder="Select" allowClear showSearch optionFilterProp="children">
                        {Array.from({ length: 4 }, (_, i) => i + 1).map((item) => (
                            <Option key={item} value={String(item)}>
                                {item} day{item > 1 ? "s" : ""}
                            </Option>
                        ))}
                        <Option value="0">Now</Option>
                    </Select>
                </Form.Item>
                <FormFooter onCancel={onCancel} canDelete={canDelete} onDelete={onDelete} />
            </Form>
        </div>
    );
}
