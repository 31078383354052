import styled from "styled-components";

import { motion } from "framer-motion";

export const NodeSettingsPanelWrapperStyled = styled(motion.div)`
    position: absolute;
    right: 0;
    top: 0;
    width: 45%;
    height: 100%;
    padding-left: 20px;
    overflow: hidden;
    z-index: -2;

    & .form-layout {
        height: 100%;

        .ant-form {
            height: calc(100% - 250px);
            overflow-x: hidden;
            overflow-y: auto;

            &::-webkit-scrollbar {
                display: none;
            }
            scrollbar-width: none;
        }
    }

    .properties {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: #fff;
        right: -150px;
        opacity: 0;
        z-index: 2;
        top: 0;
        box-shadow: -4px 0 40px rgb(26 26 73 / 0%);
        padding-left: 20px;
        padding-right: 20px;
        /* Causes flasshing issue in Firefox */
        /* transition: all 0.25s cubic-bezier(0.05, 0.03, 0.35, 1); */
        /* overflow-y: scroll; */
        border: 1px solid rgb(232, 232, 239);

        &.expanded {
            box-shadow: -4px 0 40px rgb(26 26 73 / 5%);
            z-index: 2;
        }

        & .close {
            margin-top: 100px;
            position: absolute;
            right: 20px;
            z-index: 9999;
            /* transition: all 0.25s cubic-bezier(0.05, 0.03, 0.35, 1); */

            &:hover {
                cursor: pointer;
                opacity: 0.7;
            }
        }

        & .title {
            font-size: 20px;
            font-weight: bold;
            color: #393c44;
            margin-top: 101px;

            .ant-typography {
                font-size: 20px;
                font-weight: bold;
                color: #393c44;
                margin-top: 101px;
            }
        }
    }
`;
