import React from "react";

import {
    Controls,
    Background,
    Node,
    ReactFlowInstance,
    useStoreApi,
    ConnectionLineType,
    useReactFlow,
    MarkerType,
    getOutgoers,
    getConnectedEdges,
    Edge,
    XYPosition,
    FitViewOptions,
} from "reactflow";
import { v4 as uuidv4 } from "uuid";
import { omit } from "lodash-es";
import { shallow } from "zustand/shallow";
import { message } from "antd";
import { camelCase, startCase } from "lodash-es";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import deepEqual from "deep-equal";

import { CanvasStyled, ReactFlowStyled, WorkflowLayoutStyled } from "./Workflow.styled";
import NodeSettingsPanel from "../NodeSettingsPanel/NodeSettingsPanel";
import initWorkflow, { Workflow as WorkflowType } from "../../utils/parser";
import PlaceholderNode from "../KloudMDNodes/KloudMDPlaceholderNode/KloudMDPlaceholderNode";
import KloudMDDefaultNode from "../KloudMDNodes/KloudMDDefaultNode";
import KloudMDInputNode from "../KloudMDNodes/KloudMDInputNode";
import KloudMDOutputNode from "../KloudMDNodes/KloudMDOutputNode";
import NavigationBar from "../NavigationBar";
import createGraphLayout from "../../utils/graphLayout";
import { IBranch, IDragRef, StoreNodesReduceReturn, IBlock, IWorkflowResponse } from "../../types";
import useStore, { RFState } from "../../stores";
import Sidebar from "../Sidebar";
import { getBoundingBox } from "utils/coordinates";
import SmoothStepButtonEdge from "components/KloudMDEdges/SmoothStepButtonEdge";
import { getNodesWithRemovedBranches, getNumberOfNodeWithinDefaultNode } from "utils/graphs";
import { getDepthestNodeInBranch } from "utils/graphs";
import useClickAway from "hooks/useClickAway";
import { WorkflowAPI } from "apis/workflow";
import Spinner from "components/Spinner";
import useBeforeUnbound from "hooks/useBeforeUnbound";
import useForceUpdate from "hooks/useForceudpate";

type CustomDragEvent = React.DragEvent & { id: string; positionAbsolute: { x: number; y: number } };

const MIN_DISTANCE = 250;

const proOptions = {
    hideAttribution: true,
};

const fitViewOptions: FitViewOptions = {
    maxZoom: 1,
    duration: 100,
};

const selector = (state: RFState) => ({
    nodes: state.nodes,
    edges: state.edges,
    blocks: state.blocks,
    setNodes: state.setNodes,
    setEdges: state.setEdges,
    onNodesChange: state.onNodesChange,
    onEdgesChange: state.onEdgesChange,
    onConnect: state.onConnect,
    deleteNode: state.deleteNode,
    deleteBranchNode: state.deleteBranchNode,
    setConditionFields: state.setConditionFields,
    toggleNodeActionDropdown: state.toggleNodeActionDropdown,
    initialNodes: state.initialNodes,
    setInitialNodes: state.setInitialNodes,
});

export default function Workflow() {
    const store = useStoreApi();
    const {
        nodes,
        setNodes,
        edges,
        setEdges,
        onNodesChange,
        onEdgesChange,
        onConnect,
        deleteNode,
        deleteBranchNode,
        blocks,
        setConditionFields,
        toggleNodeActionDropdown,
        initialNodes,
        setInitialNodes,
    } = useStore(selector, shallow);
    const { fitView } = useReactFlow();
    const reactFlowWrapper = React.useRef<HTMLDivElement>(null);
    const [reactFlowInstance, setReactFlowInstance] = React.useState<ReactFlowInstance>();
    const [workflow, setWorkflow] = React.useState<WorkflowType>();
    const [showNodeSettingsPanel, setShowNodeSettingsPanel] = React.useState(false);
    const [selectedNode, setSelectedNode] = React.useState<Node>();
    const [collapsed, setCollapsed] = React.useState<boolean>(false);
    const nodeTypes = React.useMemo(
        () => ({
            placeholder: PlaceholderNode,
            kloudMDDefault: KloudMDDefaultNode,
            kloudMDInput: KloudMDInputNode,
            kloudMDOutput: KloudMDOutputNode,
        }),
        []
    );
    const edgeTypes = React.useMemo(
        () => ({
            smoothStepButtonEdge: SmoothStepButtonEdge,
        }),
        []
    );
    const [messageApi, contextHolder] = message.useMessage();
    const dragRef = React.useRef<IDragRef>();
    const rightSidebarRef = React.useRef<HTMLDivElement | undefined>();
    // TODO: Move to hooks folder
    const createWorkflowMutation = useMutation((newWorkflow: IWorkflowResponse) =>
        WorkflowAPI.createWorkflow(newWorkflow)
    );
    const updateWorkflowMutation = useMutation(
        ({ workflowId, workflowData }: { workflowId: number; workflowData: IWorkflowResponse }) =>
            WorkflowAPI.updateWorkflow(workflowId, workflowData)
    );
    const navigate = useNavigate();
    const { id: workflowId } = useParams();
    const [searchParams] = useSearchParams();
    const { isLoading: isGetWorkflowLoading, data: workflowData } = useQuery(
        ["getWorkflowById", workflowId],
        () => WorkflowAPI.getWorkflowById(Number(workflowId)),
        {
            refetchOnWindowFocus: false,
            enabled: !!workflowId,
        }
    );
    const forceUpdate = useForceUpdate();
    const isPreviewMode = searchParams.get("preview_mode") || false;

    const getBlockById = (blockId: string) => blocks.find((block) => block.type === blockId);

    const canAddBranchNode = (node: Node | CustomDragEvent, closestNode: Node): boolean => {
        let nodeType: string;

        if (dragRef.current) {
            nodeType = getBlockById(dragRef.current.blockId || "")?.type ?? "";
        } else {
            nodeType = (node as Node)?.data?.nodeType ?? "";
        }

        return nodeType === "branch" && (closestNode?.data?.nodeType === "branch" || closestNode?.data?.branchId);
    };

    const getClosestEdge = React.useCallback(
        (node: Node | CustomDragEvent, isSource = false) => {
            const { nodeInternals } = store.getState();
            const storeNodes = Array.from(nodeInternals.values());

            const closestNode = storeNodes.reduce<StoreNodesReduceReturn>(
                (res, n) => {
                    const newRes = res;
                    if (
                        n.id !== node.id &&
                        n.positionAbsolute &&
                        Object.entries(n.positionAbsolute).length > 0 &&
                        node.positionAbsolute &&
                        Object.entries(node.positionAbsolute).length > 0
                    ) {
                        const nCenterX = n.positionAbsolute.x + (n?.width ?? 0) / 2;
                        const nCenterY = n.positionAbsolute.y + (n?.height ?? 0) / 2;
                        const dx = nCenterX - node.positionAbsolute.x;
                        const dy = nCenterY - node.positionAbsolute.y;
                        const d = Math.sqrt(dx * dx + dy * dy);

                        if (d < res.distance && d < MIN_DISTANCE) {
                            newRes.distance = d;
                            newRes.node = n;
                        }
                    }

                    return newRes;
                },
                {
                    distance: Number.MAX_VALUE,
                    node: null,
                }
            );

            if (!closestNode.node || canAddBranchNode(node, closestNode?.node)) {
                return null;
            }

            const closeNodeIsSource =
                (closestNode?.node?.positionAbsolute?.x ?? 0) < (node?.positionAbsolute?.x ?? 0) || isSource;

            return {
                id: `${node.id}-${closestNode.node.id}`,
                source: closeNodeIsSource ? closestNode.node.id : node.id,
                target: closeNodeIsSource ? node.id : closestNode.node.id,
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [nodes, edges]
    );

    const hasTriggerNode = React.useCallback(
        (blockId: string) => {
            const selectedBlock = blocks.find((block) => block.type === blockId);
            return (
                selectedBlock?.workflowType === "trigger" &&
                nodes?.findIndex((node) => node?.data?.workflowType === "trigger") !== -1
            );
        },
        [nodes]
    );

    const onDragStart = React.useCallback(
        (event: React.DragEvent, blockId: string) => {
            if (event.currentTarget) {
                const { centerX, centerY } = getBoundingBox(event.currentTarget);
                dragRef.current = {
                    blockId,
                    target: event.currentTarget,
                    dragX: event.clientX,
                    dragY: event.clientY,
                    centerX,
                    centerY,
                };
            }
            if (hasTriggerNode(blockId)) {
                event.preventDefault();
                messageApi.open({
                    type: "error",
                    content: "You already added a trigger.",
                });
                return;
            }
            const selectedBlock = getBlockById(blockId);
            if (
                (selectedBlock?.workflowType === "action" || selectedBlock?.workflowType === "workflow") &&
                nodes.length === 0
            ) {
                event.preventDefault();
                messageApi.open({
                    type: "error",
                    content: "You need to add a trigger.",
                });
                return;
            }

            event.dataTransfer.setData("application/reactflow", blockId);
            // eslint-disable-next-line no-param-reassign
            event.dataTransfer.effectAllowed = "move";
        },
        [hasTriggerNode, messageApi, nodes]
    );

    // ! Deprecrated
    const onDrag = React.useCallback(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (event: React.DragEvent) => {
            // if (reactFlowWrapper.current && reactFlowInstance && dragRef.current) {
            //     const draggedElement = dragRef.current;
            //     const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
            //     // Calculate the mouse position relative to react flow.
            //     const position = reactFlowInstance.project({
            //         x:
            //             event.clientX -
            //             Number(draggedElement.dragX) +
            //             Number(draggedElement?.centerX) -
            //             reactFlowBounds.left,
            //         y:
            //             event.clientY -
            //             Number(draggedElement.dragY) +
            //             Number(draggedElement?.centerY) -
            //             reactFlowBounds.top,
            //     });
            //     const closeEdge = getClosestEdge(
            //         {
            //             ...event,
            //             id: uuidv4(),
            //             positionAbsolute: {
            //                 ...position,
            //             },
            //         } as CustomDragEvent,
            //         true
            //     );
            //     if (closeEdge) {
            //         const nextNodes = nodes.map((nd) => {
            //             if (nd.id === closeEdge.source) {
            //                 if (nd.type === "kloudMDOutput" || nd.type === "kloudMDInput") {
            //                     return {
            //                         ...nd,
            //                         type: "kloudMDDefault",
            //                         className: "show-bottom-handle",
            //                         data: {
            //                             ...nd.data,
            //                             previousType: nd.type,
            //                         },
            //                     };
            //                 } else {
            //                     return {
            //                         ...nd,
            //                         className: "show-bottom-handle",
            //                     };
            //                 }
            //             } else {
            //                 const previousType = nd?.data?.previousType;
            //                 if (previousType && previousType !== undefined) {
            //                     return {
            //                         ...nd,
            //                         type: previousType,
            //                         className: "",
            //                         data: {
            //                             ...omit(nd?.data, "previousType"),
            //                         },
            //                     };
            //                 }
            //             }
            //             return {
            //                 ...nd,
            //                 className: "",
            //             };
            //         });
            //         setNodes(nextNodes);
            //     } else {
            //         const nextNodes = nodes.map((nd) => ({
            //             ...nd,
            //             className: "",
            //         }));
            //         setNodes(nextNodes);
            //     }
            // }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [reactFlowInstance, nodes]
    );

    // Refer https://bugzilla.mozilla.org/show_bug.cgi?id=505521#c80
    const onDragOver = React.useCallback(
        (event: React.DragEvent) => {
            event.preventDefault();
            // eslint-disable-next-line no-param-reassign
            event.dataTransfer.dropEffect = "move";
            if (reactFlowWrapper.current && reactFlowInstance && dragRef.current) {
                const draggedElement = dragRef.current;
                const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
                // Calculate the mouse position relative to react flow.
                const position = reactFlowInstance.project({
                    x:
                        event.clientX -
                        Number(draggedElement.dragX) +
                        Number(draggedElement?.centerX) -
                        reactFlowBounds.left,
                    y:
                        event.clientY -
                        Number(draggedElement.dragY) +
                        Number(draggedElement?.centerY) -
                        reactFlowBounds.top,
                });
                const closeEdge = getClosestEdge(
                    {
                        ...event,
                        id: uuidv4(),
                        positionAbsolute: {
                            ...position,
                        },
                    } as CustomDragEvent,
                    true
                );

                if (closeEdge) {
                    const nextNodes = nodes.map((nd) => {
                        if (nd.id === closeEdge.source) {
                            if (nd.type === "kloudMDOutput" || nd.type === "kloudMDInput") {
                                return {
                                    ...nd,
                                    type: "kloudMDDefault",
                                    className: "show-bottom-handle",
                                    data: {
                                        ...nd.data,
                                        previousType: nd.type,
                                    },
                                };
                            } else {
                                return {
                                    ...nd,
                                    className: "show-bottom-handle",
                                };
                            }
                        } else {
                            const previousType = nd?.data?.previousType;
                            if (previousType && previousType !== undefined) {
                                return {
                                    ...nd,
                                    type: previousType,
                                    className: "",
                                    data: {
                                        ...omit(nd?.data, "previousType"),
                                    },
                                };
                            }
                        }

                        return {
                            ...nd,
                            className: "",
                        };
                    });
                    setNodes(nextNodes);
                } else {
                    const nextNodes = nodes.map((nd) => ({
                        ...nd,
                        className: "",
                    }));
                    setNodes(nextNodes);
                }
            }
            return;
            // if (!showNodeSettingsPanel) {
            //     setShowNodeSettingsPanel(true);
            // }

            // if (isEmpty(selectedNode) && !isEmpty(dragRef.current)) {
            //     const block = blocks.find((b) => b.type === dragRef.current?.blockId);
            //     setSelectedNode({
            //         id: String(uuidv4()),
            //         type: "kloudMDOutput",
            //         className: "",
            //         position: {
            //             x: 0,
            //             y: 0,
            //         },
            //         data: {
            //             label: `${startCase(camelCase(block?.type.replace("_", " ")))}`,
            //             nodeType: block?.type,
            //             workflowType: block?.workflowType,
            //         },
            //     });
            // }
        },
        [reactFlowInstance, nodes]
    );

    const onDragOverSidebar = React.useCallback(
        (event: React.DragEvent) => {
            event.preventDefault();
            // eslint-disable-next-line no-param-reassign
            event.dataTransfer.dropEffect = "move";

            if (showNodeSettingsPanel) {
                setShowNodeSettingsPanel(false);
            }

            if (selectedNode) {
                setSelectedNode(undefined);
            }
        },
        [showNodeSettingsPanel, selectedNode]
    );

    const createConditionNode = ({
        parentId,
        branchId,
        position,
        block,
        isDefault = false,
    }: {
        parentId: string;
        branchId?: string;
        position: XYPosition;
        block: IBlock;
        isDefault?: boolean;
    }) => {
        return {
            id: String(uuidv4()),
            type: "kloudMDDefault",
            className: "",
            position,
            positionAbsolute: {
                ...position,
            },
            data: {
                label: isDefault ? "default" : "condition",
                nodeType: "condition",
                workflowType: block?.workflowType,
                isDefault,
                parentId: parentId,
                branchId: branchId,
            },
        };
    };

    const createPlaceholderNode = ({
        parentId,
        isFinalAction,
        position,
        block,
    }: {
        parentId: string;
        isFinalAction?: boolean;
        position: XYPosition;
        block: IBlock;
    }) => {
        return {
            id: String(uuidv4()),
            type: "placeholder",
            className: "",
            position,
            positionAbsolute: {
                ...position,
            },
            data: {
                label: "placeholder",
                nodeType: "placeholder",
                workflowType: block?.workflowType,
                parentId: parentId,
                isFinalAction: isFinalAction,
                isDefault: true,
            },
        };
    };

    /**
     * Add two conditions when a branch is created. One of them is a default condition.
     * A default condition cannot edit its value.
     * @param {Object}
     * @returns {Object}
     */
    const addNewBranchNode = ({
        sourceNode,
        newActionNode,
        position,
        block,
        nextNodes,
        nextEdges,
    }: {
        sourceNode: Node;
        newActionNode: Node;
        position: XYPosition;
        block: IBlock;
        nextNodes: Node[];
        nextEdges: Edge[];
    }) => {
        // TODO: Will move this function to the state management.
        // TODO: Will move source node to this function.

        const sourceIndex = nodes.findIndex((nd) => nd.id === sourceNode?.id);
        const newConditionNode = createConditionNode({
            parentId: newActionNode?.id,
            branchId: newActionNode?.id,
            position,
            block,
        });
        const newDefaultConditionNode = createConditionNode({
            parentId: newActionNode?.id,
            branchId: newActionNode?.id,
            position,
            block,
            isDefault: true,
        });
        const newPlaceholderNode = createPlaceholderNode({
            parentId: newConditionNode?.id,
            isFinalAction: true,
            position,
            block,
        });

        nextNodes.splice(Number(sourceIndex + 2), 0, newConditionNode);
        nextNodes.splice(Number(sourceIndex + 3), 0, newDefaultConditionNode);
        nextNodes.splice(Number(sourceIndex + 4), 0, newPlaceholderNode);

        // Sync with branch.
        const newNextNodes = nextNodes.map((nd) => {
            if (nd.id === newActionNode.id) {
                return {
                    ...newActionNode,
                    data: {
                        ...newActionNode.data,
                        branches: [
                            {
                                ...newConditionNode,
                                name: newConditionNode?.data?.label,
                                isDefault: false,
                                conditionSets: [[{ id: uuidv4(), uuid: uuidv4() }]],
                            },
                            {
                                ...newDefaultConditionNode,
                                name: newDefaultConditionNode?.data?.label,
                                isDefault: true,
                                conditionSets: [[{ id: uuidv4(), uuid: uuidv4() }]],
                            },
                        ],
                        finalActionId: newPlaceholderNode?.id,
                    },
                };
            }

            return nd;
        });

        let newNextEdges = nextEdges
            .concat({
                id: `${newActionNode.id}-${newConditionNode.id}`,
                source: newActionNode.id,
                target: newConditionNode.id,
                type: "smoothstep",
                markerEnd: {
                    type: MarkerType.ArrowClosed,
                    width: 25,
                    height: 25,
                },
            })
            .concat({
                id: `${newActionNode.id}-${newDefaultConditionNode.id}`,
                source: newActionNode.id,
                target: newDefaultConditionNode.id,
                type: "smoothstep",
                markerEnd: {
                    type: MarkerType.ArrowClosed,
                    width: 25,
                    height: 25,
                },
            });

        if (newPlaceholderNode) {
            newNextEdges = newNextEdges
                .concat({
                    id: `${newConditionNode.id}-${newPlaceholderNode.id}`,
                    source: newConditionNode.id,
                    target: newPlaceholderNode.id,
                    type: "smoothstep",
                    markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 25,
                        height: 25,
                    },
                })
                .concat({
                    id: `${newDefaultConditionNode.id}-${newPlaceholderNode.id}`,
                    source: newDefaultConditionNode.id,
                    target: newPlaceholderNode.id,
                    type: "smoothstep",
                    markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 25,
                        height: 25,
                    },
                });
        }

        return { nextNodes: newNextNodes, nextEdges: newNextEdges };
    };

    /**
     * TODO: Breaks down into smaller functions.
     * Drop an action into the canvas area then it is added to the tree if it connects with a node in the tree.
     */
    const onDrop = React.useCallback(
        (event: React.DragEvent) => {
            event.preventDefault();
            if (reactFlowWrapper.current && reactFlowInstance && dragRef.current) {
                const nodeType = event.dataTransfer.getData("application/reactflow");
                const block = blocks.find((b) => b.type === nodeType);
                const draggedElement = dragRef.current;
                const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
                const position = reactFlowInstance.project({
                    x:
                        event.clientX -
                        Number(draggedElement.dragX) +
                        Number(draggedElement.centerX) -
                        reactFlowBounds.left,
                    y:
                        event.clientY -
                        Number(draggedElement.dragY) +
                        Number(draggedElement.centerY) -
                        reactFlowBounds.top,
                });
                const newNode: Node = {
                    id: String(uuidv4()),
                    type: "kloudMDInput",
                    className: "",
                    position,
                    positionAbsolute: {
                        ...position,
                    },
                    selected: true,
                    data: {
                        label: `${startCase(camelCase(block?.name ?? ""))}`,
                        nodeType,
                        workflowType: block?.workflowType,
                    },
                };
                let newConditionNode: Node | undefined;
                // let newPlaceholderNode: Node | undefined;
                let newEdges = edges;
                let finalNode: Node | undefined;
                let sourceEdge: Edge | undefined;
                // Add trigger node.
                if (block?.workflowType === "trigger") {
                    // Add a new trigger to the tree.
                    setNodes(createGraphLayout(nodes.concat(newNode)));
                    if (block?.conditionFields) {
                        setConditionFields(block?.conditionFields ?? []);
                    }
                } else {
                    // Add a new action node to the tree.
                    const closeEdge = getClosestEdge(newNode, true);

                    if (closeEdge) {
                        let hasFinalAction = false;
                        const sourceNode = nodes.find((nd) => nd.id === closeEdge.source);

                        if (sourceNode && sourceNode?.data?.nodeType === "branch") {
                            hasFinalAction = true;
                            finalNode = nodes.find((nd) => nd.id === sourceNode?.data?.finalActionId);
                        }

                        if (sourceNode && sourceNode?.data?.branchId) {
                            hasFinalAction = true;
                            const branchNode = nodes.find((nd) => nd.id === sourceNode?.data?.branchId);
                            finalNode = nodes.find((nd) => nd.id === branchNode?.data?.finalActionId);
                        }

                        if (sourceNode) {
                            sourceEdge = getConnectedEdges([sourceNode], edges).slice(-1)?.[0];
                        }

                        let newActionNode = {
                            ...newNode,
                            type:
                                hasFinalAction || sourceNode?.data?.branchId || newNode?.data?.nodeType === "branch"
                                    ? "kloudMDDefault"
                                    : "kloudMDOutput",
                            data: {
                                ...newNode?.data,
                                parentId: closeEdge.source,
                                ...(sourceNode?.data?.branchId && { branchId: sourceNode?.data?.branchId }),
                            },
                        };

                        let nextNodes: Node[] = nodes.map((nd) => {
                            const previousType = nd?.data?.previousType;
                            if (previousType && previousType !== undefined) {
                                return {
                                    ...nd,
                                    className: "",
                                    selected: false,
                                    data: {
                                        ...omit(nd?.data, "previousType"),
                                    },
                                };
                            }

                            if (sourceNode?.data?.branchId && sourceEdge && sourceEdge.target === nd.id) {
                                return {
                                    ...nd,
                                    data: {
                                        ...nd?.data,
                                        parentId: newNode.id,
                                    },
                                };
                            }

                            return {
                                ...nd,
                                className: "",
                                selected: false,
                            };
                        });

                        if (sourceNode) {
                            // Add a new action node to the tree.
                            if (sourceNode?.data?.nodeType === "placeholder") {
                                nextNodes = nextNodes.map((nd) => {
                                    if (nd.id === sourceNode.id) {
                                        newActionNode = {
                                            ...newActionNode,
                                            id: sourceNode.id,
                                            data: {
                                                ...newActionNode.data,
                                                parentId: sourceNode?.data?.parentId,
                                                isFinalAction: sourceNode?.data?.isFinalAction,
                                            },
                                        };
                                        return newActionNode;
                                    }

                                    return nd;
                                });
                            } else if (sourceNode?.data?.nodeType === "branch") {
                                // Add a new node to a branch node.
                                const finalNodeIndex = nodes.findIndex(
                                    (nd) => nd.id === sourceNode?.data?.finalActionId
                                );
                                const numberOfLatestNodes = getNumberOfNodeWithinDefaultNode(sourceNode, nodes, edges);
                                newConditionNode = {
                                    id: String(uuidv4()),
                                    type: "kloudMDDefault",
                                    className: "",
                                    position,
                                    positionAbsolute: {
                                        ...position,
                                    },
                                    data: {
                                        label: "condition",
                                        nodeType: "condition",
                                        workflowType: block?.workflowType,
                                        parentId: closeEdge.source,
                                        branchId: sourceNode.id,
                                    },
                                };
                                nextNodes.splice(finalNodeIndex - numberOfLatestNodes, 0, newConditionNode);
                                nextNodes.splice(finalNodeIndex - numberOfLatestNodes - 1, 0, {
                                    ...newActionNode,
                                    data: {
                                        ...newActionNode.data,
                                        parentId: newConditionNode.id,
                                        branchId: sourceNode.id,
                                    },
                                });
                                nextNodes = nextNodes.map((nd) => {
                                    // Sync new action node with action's branches in the form.
                                    if (
                                        sourceNode &&
                                        nd.id === sourceNode.id &&
                                        nd?.data?.nodeType === "branch" &&
                                        newConditionNode
                                    ) {
                                        return {
                                            ...nd,
                                            data: {
                                                ...nd.data,
                                                branches: nd?.data?.branches.concat({
                                                    ...newConditionNode,
                                                    name: newConditionNode?.data?.label,
                                                    isDefault: false,
                                                    conditionSets: [[{ id: uuidv4() }]],
                                                }),
                                            },
                                        };
                                    }

                                    return nd;
                                });
                            } else if (sourceNode?.data?.branchId) {
                                // Add a new node as a branch's child.
                                const sourceIndex = nodes.findIndex((nd) => nd.id === sourceNode?.id);
                                nextNodes.splice(Number(sourceIndex + 1), 0, newActionNode);
                            } else {
                                // Add a new node to a common branch.
                                const sourceIndex = nodes.findIndex((nd) => nd.id === sourceNode?.id);
                                const outgouers: Node[] = getOutgoers(sourceNode, nodes, edges);
                                nextNodes.splice(Number(sourceIndex + outgouers.length + 1), 0, newActionNode);
                            }
                        }

                        // Add a new branch node to the tree.
                        // Attach branch node's conditions to itself.
                        if (
                            sourceNode &&
                            block &&
                            sourceNode?.data?.nodeType !== "branch" &&
                            newActionNode?.data?.nodeType === "branch"
                        ) {
                            const { nextNodes: newNextNodes, nextEdges: newNextEdges } = addNewBranchNode({
                                sourceNode,
                                newActionNode,
                                position,
                                block,
                                nextNodes,
                                nextEdges: newEdges,
                            });
                            nextNodes = newNextNodes;
                            newEdges = newNextEdges;
                        }
                        // ! Deprecrated
                        // if (sourceNode?.data?.nodeType !== "branch" && newActionNode?.data?.nodeType === "branch") {
                        // const sourceIndex = nodes.findIndex((nd) => nd.id === sourceNode?.id);
                        // newConditionNode = {
                        //     id: String(uuidv4()),
                        //     type: "kloudMDDefault",
                        //     className: "",
                        //     position,
                        //     positionAbsolute: {
                        //         ...position,
                        //     },
                        //     data: {
                        //         label: "condition",
                        //         nodeType: "condition",
                        //         workflowType: block?.workflowType,
                        //         parentId: newActionNode.id,
                        //         // default: true,
                        //         branchId: newActionNode.id,
                        //     },
                        // };
                        // nextNodes.splice(Number(sourceIndex + 2), 0, newConditionNode);
                        // const newDefaultConditionNode = {
                        //     id: String(uuidv4()),
                        //     type: "kloudMDDefault",
                        //     className: "",
                        //     position,
                        //     positionAbsolute: {
                        //         ...position,
                        //     },
                        //     data: {
                        //         label: "default",
                        //         nodeType: "condition",
                        //         workflowType: block?.workflowType,
                        //         parentId: newActionNode.id,
                        //         default: true,
                        //         branchId: newActionNode.id,
                        //     },
                        // };
                        // nextNodes.splice(Number(sourceIndex + 3), 0, newDefaultConditionNode);
                        // newPlaceholderNode = {
                        //     id: String(uuidv4()),
                        //     type: "placeholder",
                        //     className: "",
                        //     position,
                        //     positionAbsolute: {
                        //         ...position,
                        //     },
                        //     data: {
                        //         label: "placeholder",
                        //         nodeType: "placeholder",
                        //         workflowType: block?.workflowType,
                        //         parentId: newConditionNode.id,
                        //         isFinalAction: sourceNode?.data?.isFinalAction,
                        //         default: true,
                        //     },
                        // };
                        // nextNodes.splice(Number(sourceIndex + 4), 0, newPlaceholderNode);
                        // nextNodes = nextNodes.map((nd) => {
                        //     if (nd.id === newActionNode.id) {
                        //         return {
                        //             ...newActionNode,
                        //             data: {
                        //                 ...newActionNode.data,
                        //                 branches: [
                        //                     {
                        //                         ...newConditionNode,
                        //                         name: newConditionNode?.data?.label,
                        //                         conditionSets: [[{ id: uuidv4() }]],
                        //                     },
                        //                 ],
                        //                 finalActionId: newPlaceholderNode?.id,
                        //             },
                        //         };
                        //     }
                        //     return nd;
                        // });
                        // }

                        // Connect a source node to a condition node or a normal node.
                        if (sourceNode?.data?.nodeType === "branch" && newConditionNode) {
                            newEdges = newEdges.concat({
                                id: `${closeEdge.source}-${newConditionNode.id}`,
                                source: closeEdge.source,
                                target: newConditionNode.id,
                                type: "smoothstep",
                                markerEnd: {
                                    type: MarkerType.ArrowClosed,
                                    width: 25,
                                    height: 25,
                                },
                            });
                        } else {
                            newEdges = newEdges.concat({
                                id: `${closeEdge.source}-${newNode.id}`,
                                source: closeEdge.source,
                                target: newNode.id,
                                type: "smoothstep",
                                markerEnd: {
                                    type: MarkerType.ArrowClosed,
                                    width: 25,
                                    height: 25,
                                },
                            });
                        }

                        // Connect a condtion node with a common node and branch's final node.
                        if (sourceNode?.data?.nodeType === "branch" && finalNode && newConditionNode) {
                            newEdges = newEdges
                                .concat({
                                    id: `${newConditionNode.id}-${newNode.id}`,
                                    source: newConditionNode.id,
                                    target: newNode.id,
                                    type: "smoothstep",
                                    markerEnd: {
                                        type: MarkerType.ArrowClosed,
                                        width: 25,
                                        height: 25,
                                    },
                                })
                                .concat({
                                    id: `${newNode.id}-${finalNode.id}`,
                                    source: newNode.id,
                                    target: finalNode.id,
                                    type: "smoothstep",
                                    markerEnd: {
                                        type: MarkerType.ArrowClosed,
                                        width: 25,
                                        height: 25,
                                    },
                                    data: {
                                        showStartButton: true,
                                    },
                                });
                        }

                        // Connect a node to source target.
                        if (sourceNode?.data?.branchId && finalNode && sourceEdge) {
                            newEdges = newEdges
                                .concat({
                                    id: `${newNode.id}-${sourceEdge.target}`,
                                    source: newNode.id,
                                    target: sourceEdge.target,
                                    type: "smoothstep",
                                    markerEnd: {
                                        type: MarkerType.ArrowClosed,
                                        width: 25,
                                        height: 25,
                                    },
                                    ...(sourceEdge.target === finalNode.id && {
                                        data: {
                                            showStartButton: true,
                                        },
                                    }),
                                })
                                .filter((ed) => {
                                    return ed.id !== sourceEdge?.id;
                                });
                        }

                        // ! Deprecrated
                        // If the node is branch then it is connected with a default condition node.
                        // if (newActionNode?.data?.nodeType === "branch" && newConditionNode) {
                        //     newEdges = newEdges.concat({
                        //         id: `${newActionNode.id}-${newConditionNode.id}`,
                        //         source: newActionNode.id,
                        //         target: newConditionNode.id,
                        //         type: "smoothstep",
                        //         markerEnd: {
                        //             type: MarkerType.ArrowClosed,
                        //             width: 25,
                        //             height: 25,
                        //         },
                        //     });

                        //     if (newPlaceholderNode) {
                        //         newEdges = newEdges.concat({
                        //             id: `${newConditionNode.id}-${newPlaceholderNode.id}`,
                        //             source: newConditionNode.id,
                        //             target: newPlaceholderNode.id,
                        //             type: "smoothstep",
                        //             markerEnd: {
                        //                 type: MarkerType.ArrowClosed,
                        //                 width: 25,
                        //                 height: 25,
                        //             },
                        //         });
                        //     }
                        // }

                        //  Update the final node's parent id by the deepest node in the branch when adding a new node to the branch.
                        if (sourceNode?.data?.nodeType === "branch" || sourceNode?.data?.branchId) {
                            const branchId =
                                sourceNode?.data?.nodeType === "branch" ? sourceNode.id : sourceNode?.data?.branchId;
                            if (branchId) {
                                const branch = nodes?.find((nd) => nd.id === branchId);
                                if (branchId && finalNode) {
                                    const depthestNode = getDepthestNodeInBranch(
                                        branchId as string,
                                        nextNodes,
                                        newEdges
                                    );
                                    nextNodes = nextNodes.map((nd) => {
                                        if (nd.id === branch?.data?.finalActionId) {
                                            return {
                                                ...nd,
                                                data: {
                                                    ...nd.data,
                                                    parentId: depthestNode?.id,
                                                },
                                            };
                                        }

                                        return nd;
                                    });
                                }
                            }
                        }

                        setSelectedNode(newActionNode);
                        setNodes(createGraphLayout(nextNodes));
                        setEdges(newEdges);
                    } else {
                        const nextNodes = nodes.map((nd) => ({
                            ...nd,
                            className: "",
                        }));
                        setNodes(createGraphLayout(nextNodes));
                        setShowNodeSettingsPanel(false);
                        setSelectedNode(undefined);
                    }
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [reactFlowInstance, nodes, edges]
    );

    const onDragEnd = () => {
        dragRef.current = undefined;
    };

    /**
     * Disable the ability to open a trigger form when a trigger node is selected.
     * @param event
     * @param node
     */
    const onNodeClick = (event: React.MouseEvent, node: Node) => {
        // Close all header dropdowns.
        toggleNodeActionDropdown();

        if (node?.data?.workflowType === "trigger" || node?.type === "placeholder") return;

        setShowNodeSettingsPanel(true);
        if (node?.data?.nodeType === "condition") {
            const branch = nodes?.find((nd) => nd.id === node?.data?.branchId);
            if (branch) {
                setSelectedNode({
                    ...branch,
                    data: {
                        ...branch.data,
                        selectedBranch: node?.id,
                    },
                });
            }
        } else {
            setSelectedNode(node);
        }
    };

    const handleDeleteNodes = () => {
        if (selectedNode) {
            if (selectedNode?.data?.branchId) {
                deleteBranchNode(selectedNode.id);
            } else {
                deleteNode(selectedNode.id);
            }
            setSelectedNode(undefined);
            setShowNodeSettingsPanel(false);
        }
    };

    const handleCloseSettingsPanel = React.useCallback(() => {
        const nextNodes = nodes.map((nd) => {
            if (selectedNode && nd.id === selectedNode.id) {
                return {
                    ...nd,
                    selected: false,
                };
            }

            return nd;
        });
        setNodes(nextNodes);
        setSelectedNode(undefined);
        setShowNodeSettingsPanel(false);
    }, [nodes, selectedNode, setNodes]);

    const updateChildNodes = (nodeId: string, nextNodes: Node[], data: any): Node[] => {
        const currentNode = reactFlowInstance?.getNode(nodeId);
        if (currentNode) {
            const branches = data?.branches ?? [];
            return nextNodes.map((node) => {
                const branch = branches.find((item: IBranch) => item.id === node.id);
                if (branch) {
                    return {
                        ...node,
                        data: {
                            ...node.data,
                            label: branch.name,
                            condition: {
                                ...node.data.condition,
                                conditionSets: branch.conditionSets,
                            },
                        },
                    };
                }

                return node;
            });
        }

        return nextNodes;
    };

    const removeBranchChildNodes = (nodeId: string, nextNodes: Node[], data: any): Node[] => {
        const branches = data?.branches ?? [];
        const currentNode = reactFlowInstance?.getNode(nodeId);
        if (currentNode) {
            const { nodes: newNodes } = getNodesWithRemovedBranches(currentNode, nextNodes, edges, branches as Node[]);

            return newNodes;
        }

        return nextNodes;
    };

    const handleUpdateNode = (data: any) => {
        let nextNodes = nodes.map((nd) => {
            if (selectedNode && nd.id === selectedNode.id) {
                return {
                    ...nd,
                    data: {
                        ...nd.data,
                        ...data,
                    },
                };
            }

            return nd;
        });

        if (selectedNode?.data?.nodeType === "branch") {
            nextNodes = updateChildNodes(selectedNode.id, nextNodes, data);
            nextNodes = removeBranchChildNodes(selectedNode.id, nextNodes, data);
        }

        setNodes(nextNodes);
        setSelectedNode(undefined);
        setShowNodeSettingsPanel(false);
    };

    const handleToggleSidebar = () => {
        setCollapsed((state) => !state);
        setTimeout(() => {
            fitView(fitViewOptions);
        }, 310);
    };

    const createWorkflow = async (newWorkflow: IWorkflowResponse) => {
        try {
            if (!workflow) return;

            const workflowTriggerId = blocks.find((block) => block.type === workflow.trigger.type)?.id;

            const response = await createWorkflowMutation.mutateAsync({
                ...omit(newWorkflow, ["projectId"]),
                uuid: uuidv4(),
                trigger: {
                    ...workflow.trigger,
                    workflowTriggerId,
                    uuid: uuidv4(),
                },
            });
            navigate(`/workflows/${response?.id}`, { replace: true });
            messageApi.open({
                type: "success",
                content: "The workflow is created successfully.",
            });
        } catch (e) {
            console.log(e);
            messageApi.open({
                type: "error",
                content: "Cannot create a new workflow.",
            });
        }
    };

    const updateWorkflow = async (newWorkflow: IWorkflowResponse) => {
        try {
            if (!workflow) return;

            const workflowTriggerId = blocks.find((block) => block.type === workflow.trigger.type)?.id;

            const response = await updateWorkflowMutation.mutateAsync({
                workflowId: Number(workflowId),
                workflowData: {
                    ...newWorkflow,
                    uuid: uuidv4(),
                    trigger: {
                        ...workflow.trigger,
                        workflowTriggerId,
                        uuid: uuidv4(),
                    },
                },
            });
            // ? Always create a new workflow
            navigate(`/workflows/${response?.id}`, { replace: true });
            messageApi.open({
                type: "success",
                content: "The workflow is updated successfully.",
            });
        } catch (e) {
            console.log(e);
            messageApi.open({
                type: "error",
                content: "Cannot update the workflow.",
            });
        }
    };

    const validateIncompleteBranchAction = (nds: Node[]): [boolean, Node[]] => {
        let isValid = true;
        const newNodes = nds.map((nd) => {
            if (nd?.data?.nodeType === "branch") {
                isValid = nd?.data?.branches
                    ?.filter((branch: Node) => {
                        // Only check this condition when creating a new workflow.
                        if (!workflowId) {
                            return branch?.data?.nodeType === "condition" && !branch?.data?.isDefault;
                        }

                        return true;
                    })
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    .every((branch: any) => {
                        return branch?.conditionSets?.every((conditionSet: any) => {
                            return conditionSet?.every((condition: any) => {
                                return !!condition?.field && !!condition?.condition && !!condition?.value;
                            });
                        });
                    });

                return {
                    ...nd,
                    data: {
                        ...nd.data,
                        isValid,
                    },
                };
            }

            return nd;
        });

        return [isValid, newNodes];
    };

    const validateBranchConditions = (nds: Node[]): [boolean, Node[]] => {
        let isValid = true;

        const newNodes = nds.map((nd) => {
            if (nd?.data?.nodeType === "condition") {
                const childNode = getOutgoers(nd, nodes, edges)?.[0];

                if (childNode?.data?.nodeType === "placeholder") {
                    isValid = false;
                }

                return {
                    ...nd,
                    data: {
                        ...nd.data,
                        isValid,
                    },
                };
            }

            return nd;
        });

        return [isValid, newNodes];
    };

    const validatePlaceholder = (nds: Node[]): [boolean, Node[]] => {
        let isValid = true;
        const newNodes = nds.map((nd) => {
            if (nd?.data?.nodeType === "placeholder") {
                isValid = false;

                return {
                    ...nd,
                    data: {
                        ...nd.data,
                        isValid,
                    },
                };
            }

            return nd;
        });

        return [isValid, newNodes];
    };

    const validateWorkflow = React.useCallback((): boolean => {
        const [isBranchActionValid, newNodesWithBranch] = validateIncompleteBranchAction(nodes);
        const [isBranchConditionValid, newNodesWithCondition] = validateBranchConditions(newNodesWithBranch);
        const [isPlaceholderValid, newNodesWithPlaceholder] = validatePlaceholder(newNodesWithCondition);

        setNodes(newNodesWithPlaceholder);

        if (!isBranchActionValid) {
            messageApi.open({
                type: "error",
                content: "You have an incomplete branch action.",
            });

            return false;
        }

        if (!isBranchConditionValid) {
            messageApi.open({
                type: "error",
                content: "You need to add at least one action for the branch condition.",
            });

            return false;
        }

        if (!isPlaceholderValid) {
            messageApi.open({
                type: "error",
                content: "You need to update the placeholder.",
            });

            return false;
        }

        return isBranchActionValid && isBranchConditionValid && isPlaceholderValid;
    }, [nodes]);

    const handleSave = async () => {
        if (!validateWorkflow()) {
            return;
        }

        const newWorkflow = workflow?.convertReactflowToWorkflow(workflow, nodes, edges);
        if (newWorkflow) {
            if (!workflowId) {
                await createWorkflow(newWorkflow);
            } else {
                await updateWorkflow(newWorkflow);
            }
        }
    };

    const handlePanelClick = () => {
        // Close all header dropdowns.
        toggleNodeActionDropdown();

        if (showNodeSettingsPanel) {
            handleCloseSettingsPanel();
        }
    };

    const getConditionFieldsFrom = (tree: WorkflowType) => {
        const conditionFields = blocks.find((block: IBlock) => block.type === tree?.trigger?.type)?.conditionFields;
        if (conditionFields) {
            setConditionFields(conditionFields ?? []);
        }
    };

    const handleUpdateWorkflowName = (value: string) => {
        if (!workflow) return;

        setWorkflow((prevWorkflow) => {
            if (!prevWorkflow) return prevWorkflow;

            prevWorkflow.updateName(value);

            return prevWorkflow;
        });
        forceUpdate();
    };

    useBeforeUnbound(() => {
        return !deepEqual(
            initialNodes.map((node: Node) =>
                omit(node, ["width", "height", "selected", "dragging", "data.visibleActions", "data.isValid"])
            ),
            nodes.map((node: Node) =>
                omit(node, ["width", "height", "selected", "dragging", "data.visibleActions", "data.isValid"])
            )
        );
    }, "Are you sure, you want to exit?");

    useClickAway(rightSidebarRef as React.RefObject<HTMLDivElement | undefined>, () => {
        if (!document.getElementsByClassName("tox")) {
            handleCloseSettingsPanel();
        }
    });

    React.useEffect(() => {
        let data = {
            name: "New Workflow",
        };
        if (workflowId && workflowData) {
            data = workflowData;
        }
        const tree = initWorkflow(data);
        setWorkflow(tree);
        getConditionFieldsFrom(tree);
        const { nodes: newNodes, edges: newEdges } = tree.buildReactflowTree();
        if (newNodes && newEdges) {
            const laidOutNodes = createGraphLayout(newNodes || []);
            setInitialNodes(laidOutNodes || []);
            setNodes(laidOutNodes || []);
        }
        if (newEdges) {
            setEdges(newEdges);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workflowId, workflowData]);

    if (workflowId && isGetWorkflowLoading) return <Spinner fullscreen={true} />;

    return (
        <WorkflowLayoutStyled className="main">
            {contextHolder}
            {!isPreviewMode && (
                <NavigationBar
                    workflowName={workflow?.name || ""}
                    onSave={() => void handleSave()}
                    onUpdateName={handleUpdateWorkflowName}
                    isLoading={createWorkflowMutation.isLoading || updateWorkflowMutation.isLoading}
                />
            )}
            <div className="container">
                {!isPreviewMode && (
                    <Sidebar
                        onDrag={onDrag}
                        onDragStart={onDragStart}
                        onDragEnd={onDragEnd}
                        onDragOver={onDragOverSidebar}
                        collapsed={collapsed}
                        onToggleSidebar={() => handleToggleSidebar()}
                    />
                )}
                <NodeSettingsPanel
                    node={selectedNode}
                    open={showNodeSettingsPanel}
                    onClose={() => handleCloseSettingsPanel()}
                    onDeleteNodes={() => handleDeleteNodes()}
                    onSubmit={(data) => handleUpdateNode(data)}
                    wrapperRef={rightSidebarRef}
                />
                <CanvasStyled className="canvas" isPreviewMode={!!isPreviewMode} ref={reactFlowWrapper}>
                    <ReactFlowStyled
                        nodeTypes={nodeTypes}
                        edgeTypes={edgeTypes}
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        onInit={setReactFlowInstance}
                        onDragOver={onDragOver}
                        onDrop={onDrop}
                        onNodeClick={onNodeClick}
                        fitView
                        fitViewOptions={fitViewOptions}
                        proOptions={proOptions}
                        connectionLineType={ConnectionLineType.SmoothStep}
                        onPaneClick={() => handlePanelClick()}
                    >
                        <Controls />
                        <Background />
                    </ReactFlowStyled>
                </CanvasStyled>
            </div>
        </WorkflowLayoutStyled>
    );
}
