import React from "react";

import { INavigatioProps } from "../../types";
import Arrow from "../../assets/arrow.svg";
import { NavigationLeftSideStyled, NavigationStyled, NavigatioButtonLeftStyled } from "./NavigationBar.styled";
import { Button } from "antd";
import EditableText from "components/EditableText";

export default function Navigation({ workflowName, onUpdateName, onSave, isLoading = false }: INavigatioProps) {
    const onBack = () => {
        const url = `${process.env.REACT_APP_DASHBOARD_BASE_URL || "https://dashboard.pilotpractice.com"}/workflows`;
        window.location.assign(url);
    };

    return (
        <NavigationStyled id="navigation">
            <NavigationLeftSideStyled>
                <div className="details">
                    <div className="back" onClick={() => onBack()} aria-hidden>
                        <img src={Arrow} alt="Arrow" />
                    </div>
                    <div className="name">
                        <EditableText text={workflowName} onSave={onUpdateName} />
                    </div>
                </div>
            </NavigationLeftSideStyled>
            <div id="centerswitch"></div>
            <NavigatioButtonLeftStyled id="buttonsright" className="discard">
                <Button type="default" size="large">
                    Discard
                </Button>
                <Button type="primary" size="large" className="publish" onClick={() => onSave()} loading={isLoading}>
                    Save
                </Button>
            </NavigatioButtonLeftStyled>
        </NavigationStyled>
    );
}
