import React from "react";

import { Form } from "antd";

import { IBranches, IFormsProps } from "../../../types";
import ConditionEditor from "components/ConditionEditor";
import FormFooter from "../FormFooter";

interface IFormInputs {
    title: string;
    conditions: IBranches;
}

export default function BranchForm({ initialData, canDelete = true, onCancel, onSubmit, onDelete }: IFormsProps) {
    const [form] = Form.useForm();

    const sumbit = (data: IFormInputs) => {
        onSubmit({
            ...data,
        });
    };

    return (
        <div className="form-layout">
            <Form layout="vertical" form={form} onFinish={sumbit}>
                {initialData?.branches && (
                    <Form.Item label="Branches" name="branches">
                        <ConditionEditor
                            initialValues={initialData?.branches ?? []}
                            selectedBranch={initialData?.selectedBranch}
                        />
                    </Form.Item>
                )}
                <FormFooter onCancel={onCancel} canDelete={canDelete} onDelete={onDelete} />
            </Form>
        </div>
    );
}
