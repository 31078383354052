import {
    ThunderboltFilled,
    ClockCircleFilled,
    MailFilled,
    MessageFilled,
    UserAddOutlined,
    UsergroupAddOutlined,
    UserSwitchOutlined,
    ApartmentOutlined,
    // CarryOutFilled,
    CommentOutlined,
    CalendarFilled,
    // ContactsFilled,
    PhoneFilled,
    // CheckSquareFilled,
    // SnippetsFilled,
    // ScheduleFilled,
    // FileTextFilled,
    // ControlFilled,
    // CheckCircleFilled,
    // AliwangwangFilled,
    // UserDeleteOutlined,
} from "@ant-design/icons";

import { TBlockIcons } from "types";

export const BLOCKS: TBlockIcons = [
    {
        type: "lead.created",
        workflowType: "trigger",
        description: "Triggers when somebody performs a specified action.",
        icon: <UserAddOutlined style={{ color: "#808292", fontSize: "20px" }} />,
    },
    {
        type: "lead.updated",
        workflowType: "trigger",
        description: "Triggers when somebody performs a specified action.",
        icon: <UsergroupAddOutlined style={{ color: "#808292", fontSize: "20px" }} />,
    },
    {
        type: "lead.status.updated",
        workflowType: "trigger",
        description: "Triggers when somebody performs a specified action.",
        icon: <UserSwitchOutlined style={{ color: "#808292", fontSize: "20px" }} />,
    },
    // ! Deprecrated
    // {
    //     type: "contact.days_in_stage",
    //     workflowType: "trigger",
    //     description: "Triggers when somebody performs a specified action.",
    //     icon: <CarryOutFilled style={{ color: "#808292", fontSize: "20px" }} />,
    // },
    {
        type: "lead.replied",
        workflowType: "trigger",
        description: "Triggers when somebody performs a specified action.",
        icon: <CommentOutlined style={{ color: "#808292", fontSize: "20px" }} />,
    },
    {
        type: "event.created",
        workflowType: "trigger",
        description: "Triggers when somebody performs a specified action.",
        icon: <CalendarFilled style={{ color: "#808292", fontSize: "20px" }} />,
    },
    // ! Depercrated
    // {
    //     type: "appointment.status_updated",
    //     workflowType: "trigger",
    //     description: "Triggers when somebody performs a specified action.",
    //     icon: <ContactsFilled style={{ color: "#808292", fontSize: "20px" }} />,
    // },
    {
        type: "call.missed",
        workflowType: "trigger",
        description: "Triggers when somebody performs a specified action.",
        icon: <PhoneFilled style={{ color: "#808292", fontSize: "20px" }} />,
    },
    {
        type: "estimate.created",
        workflowType: "trigger",
        description: "Triggers when somebody performs a specified action.",
        icon: <ThunderboltFilled style={{ color: "#808292", fontSize: "20px" }} />,
    },
    // {
    //     type: "create_task",
    //     workflowType: "action",
    //     description: "Performs or edits a specified action.",
    //     icon: <CheckSquareFilled style={{ color: "#808292", fontSize: "20px" }} />,
    // },
    // ! Depercrated
    // {
    //     type: "create_appointment",
    //     workflowType: "action",
    //     description: "Performs or edits a specified action.",
    //     icon: <ScheduleFilled style={{ color: "#808292", fontSize: "20px" }} />,
    // },
    // {
    //     type: "create_note",
    //     workflowType: "action",
    //     description: "Performs or edits a specified action.",
    //     icon: <SnippetsFilled style={{ color: "#808292", fontSize: "20px" }} />,
    // },
    // {
    //     type: "update_contact_stage",
    //     workflowType: "action",
    //     description: "Performs or edits a specified action.",
    //     icon: <FileTextFilled style={{ color: "#808292", fontSize: "20px" }} />,
    // },
    // {
    //     type: "update_contact_field",
    //     workflowType: "action",
    //     description: "Performs or edits a specified action.",
    //     icon: <ControlFilled style={{ color: "#808292", fontSize: "20px" }} />,
    // },
    {
        type: "email",
        name: "Send Email",
        workflowType: "action",
        description: "Performs or edits a specified action.",
        icon: <MailFilled style={{ color: "#808292", fontSize: "20px" }} />,
    },
    {
        type: "sms",
        name: "Send SMS",
        workflowType: "action",
        description: "Performs or edits a specified action.",
        icon: <MessageFilled style={{ color: "#808292", fontSize: "20px" }} />,
    },
    // {
    //     type: "update_appointment_status",
    //     workflowType: "action",
    //     description: "Performs or edits a specified action.",
    //     icon: <ControlFilled style={{ color: "#808292", fontSize: "20px" }} />,
    // },
    // {
    //     type: "create_ticket",
    //     workflowType: "action",
    //     description: "Performs or edits a specified action.",
    //     icon: <CheckCircleFilled style={{ color: "#808292", fontSize: "20px" }} />,
    // },
    // {
    //     type: "assign_to_user",
    //     workflowType: "action",
    //     description: "Performs or edits a specified action.",
    //     icon: <AliwangwangFilled style={{ color: "#808292", fontSize: "20px" }} />,
    // },
    // {
    //     type: "remove_assigned_user",
    //     workflowType: "action",
    //     description: "Performs or edits a specified action.",
    //     icon: <UserDeleteOutlined style={{ color: "#808292", fontSize: "20px" }} />,
    // },
    {
        type: "branch",
        workflowType: "workflow",
        description: "Performs or edits a specified action.",
        icon: <ApartmentOutlined style={{ color: "#808292", fontSize: "20px" }} />,
    },
    {
        type: "wait",
        workflowType: "workflow",
        description: "Performs or edits a specified action.",
        icon: <ClockCircleFilled style={{ color: "#808292", fontSize: "20px" }} />,
    },
    // ? Should implement it.
    // {
    //     type: "jump",
    //     workflowType: "action",
    //     description: "Performs or edits a specified action.",
    // },
];
