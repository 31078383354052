import styled from "styled-components";

export const NavigationStyled = styled.div`
    height: 71px;
    background-color: #fff;
    border: 1px solid #e8e8ef;
    width: 100%;
    display: table;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    z-index: 9;
`;

export const NavigationLeftSideStyled = styled.div`
    display: inline-block;
    /* vertical-align: middle; */
    margin-left: 20px;

    .details {
        display: flex;
        justify-content: start;
        align-items: center;

        .back {
            cursor: pointer;
            width: 40px;
            height: 40px;
            border-radius: 100px;
            background-color: #f1f4fc;
            text-align: center;
            display: inline-block;
            vertical-align: top;
            margin-top: 12px;
            margin-right: 10px;

            img {
                margin-top: 13px;
            }
        }

        .name {
            margin-top: 15px;
            display: inline-block;

            .ant-typography {
                font-weight: 500;
                font-size: 16px;
                color: #393c44;
                margin-bottom: 0;

                .ant-typography-edit {
                    margin-left: 15px;
                    color: #393c44;
                }

                &.ant-typography-edit-content {
                    margin-left: 15px;
                }
            }
        }
    }
`;

export const NavigatioButtonLeftStyled = styled.div`
    float: right;
    margin-top: 15px;

    .publish {
        margin-left: 10px;
        margin-right: 20px;
    }
`;
