import React from "react";

import { Button, Card, Select, Input } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { shallow } from "zustand/shallow";

import { IConditionProps } from "types";
// import { Operator } from "contestants";
import useConditionEditorStore from "stores/useConditionEditorStore";
import { ConditionWrapperStyled, ConditionWrapperFormStyled } from "./Condition.styled";
import useStore, { RFState } from "../../../stores";

const selector = (state: RFState) => ({
    conditionFields: state.conditionFields,
});

export default function Condition({
    branchId,
    conditionSetIndex,
    conditionIndex,
    condition,
    canDelete = false,
    isDefault = false,
}: IConditionProps) {
    const { conditionFields } = useStore(selector, shallow);
    const { deleteCondition, onConditionChange } = useConditionEditorStore();
    const [operators, setOperators] = React.useState<string[]>([]);

    const getConditionOperators = (value: string) => {
        const conditionOperators =
            conditionFields.find((conditionField) => conditionField.field === value)?.operations ?? [];

        setOperators(conditionOperators);
    };

    const handleConditionChange = (fieldName: string, value: any) => {
        onConditionChange(branchId, conditionSetIndex, conditionIndex, fieldName, value);

        if (fieldName === "field") {
            getConditionOperators(value as string);
        }
    };

    const handleDeleteCondition = () => {
        deleteCondition(branchId, conditionSetIndex, conditionIndex);
    };

    React.useEffect(() => {
        if (condition?.field) {
            getConditionOperators(condition?.field);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ConditionWrapperStyled isDefault={isDefault}>
            <Card>
                <div>{conditionIndex >= 1 && <span>AND</span>}</div>
                <ConditionWrapperFormStyled>
                    <div className="conditon-form">
                        <Select
                            className="field"
                            value={condition?.field}
                            onChange={(value) => handleConditionChange("field", value)}
                            options={conditionFields?.map((conditionField) => ({
                                label: conditionField.label,
                                value: conditionField.field,
                            }))}
                            placeholder="Select"
                            disabled={isDefault}
                            getPopupContainer={() => document.getElementById("nodeSettingsPanelWrapper") as HTMLElement}
                        />
                        <Select
                            className="condition"
                            value={condition?.condition}
                            onChange={(value) => handleConditionChange("condition", value)}
                            options={operators.map((operator) => ({
                                label: operator,
                                value: operator,
                            }))}
                            placeholder="Select Operator"
                            disabled={isDefault}
                            getPopupContainer={() => document.getElementById("nodeSettingsPanelWrapper") as HTMLElement}
                        />
                        <Input
                            className="value"
                            value={condition?.value}
                            placeholder="Value"
                            onChange={(e) => handleConditionChange("value", e.target.value)}
                            disabled={isDefault}
                        />
                    </div>
                    {canDelete && (
                        <Button icon={<DeleteOutlined />} onClick={() => handleDeleteCondition()} type="text"></Button>
                    )}
                </ConditionWrapperFormStyled>
            </Card>
        </ConditionWrapperStyled>
    );
}
